@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=65f6d508-65d5-46f4-9c44-d7b19e6c8456");
:root {
  --textColor: #000;
}
:root {
  --spacePart: 10px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 20px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 20px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 20px;
  }
}
:root {
  --spaceTotal: 30px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 30px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 30px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #000;
  line-height: 1.375;
  cursor: pointer;
  color: #fff;
  padding: 28px 55px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 3px solid #000;
  font-weight: bold;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.button:hover,
.button:focus {
  color: #000;
  background-color: #FFCD00;
}
.button:active {
  background-color: #FFB71B;
}
@font-face {
  font-family: "Avenir Next LT W01 Regular";
  src: url("/extras/fonts/c4691e94-080d-4452-8f9f-8b3db6b68d55.woff2") format("woff2"), url("/extras/fonts/a1adabe6-efe5-40b7-b619-48111c8ff883.woff") format("woff");
}
@font-face {
  font-family: "Avenir Next LT W01 Medium";
  src: url("/extras/fonts/f5d4f93b-454a-43e0-9ae8-7f1271b5c0fd.woff2") format("woff2"), url("/extras/fonts/72556e8c-c677-48d8-81ff-3eb8fc50033b.woff") format("woff");
}
@font-face {
  font-family: "Avenir Next LT W01 Bold";
  src: url("/extras/fonts/b56355b0-7729-42a7-9f65-62c7211d078c.woff2") format("woff2"), url("/extras/fonts/05537afc-e923-4df9-b365-71798fbd220d.woff") format("woff");
}
/* nunito-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('/extras/fonts/nunito-v26-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-500 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  src: url('/extras/fonts/nunito-v26-latin-500.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url('/extras/fonts/nunito-v26-latin-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #FFCD00;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: bold;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
  line-height: 1.375;
}
.unit caption {
  display: none;
}
.flag {
  background: #FFCD00;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
  position: relative;
}
.unit .head,
.unit .body,
.unit .foot {
  float: left;
  width: 100%;
}
.unit.fold div.less,
.unit.fold div.more {
  float: left;
  width: 100%;
}
.unit.fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  position: relative;
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
div.load a.load {
  display: inline-block;
  padding-left: 26px;
  background-size: 16px 16px;
  background-position: 0 3px;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-download-black.svg);
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid var(--textColor);
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.cb-service {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.cb-service:first-child {
  margin-top: 0;
}
.cb-service:last-child {
  margin-bottom: 0;
}
.cb-youtube iframe,
.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  color: var(--textColor);
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
  height: 100%;
}
#slides {
  float: left;
  width: 100%;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding-bottom: 0;
  position: relative;
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.cb-layout1 #slides img {
  object-position: right;
}
@media (max-width: 767px) {
  .cb-layout1 #slides img {
    object-position: 80%;
  }
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: #FFCD00;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #FFCD00;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'Nunito', sans-serif;
  line-height: 1.42857143;
  color: var(--textColor);
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #FFCD00;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #FFCD00;
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  background-color: #000;
  line-height: 1.375;
  cursor: pointer;
  color: #fff;
  padding: 28px 55px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 3px solid #000;
  font-weight: bold;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  color: #000;
  background-color: #FFCD00;
}
#disp .foot input:active,
#disp .foot a:active {
  background-color: #FFB71B;
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1023px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'Nunito', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.unit.form .part {
  margin-top: 5px;
  margin-bottom: 5px;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
  background-color: #FFF;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding: 10px 10px 0;
  box-sizing: border-box;
  color: #707070;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 40px;
    padding-top: 9px;
    padding-bottom: 9px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #FFF;
  min-height: 40px;
  padding: 5px 10px;
  -webkit-appearance: none;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 9px;
    padding-bottom: 9px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 22px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 9px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  line-height: 1.375;
  background: #FFF;
  height: 40px;
  border: 1px solid var(--textColor);
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}
.unit.form .ship div.chop input {
  float: left;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form .foot {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.unit.form input.submit {
  float: right;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  background-color: #000;
  line-height: 1.375;
  cursor: pointer;
  color: #fff;
  padding: 28px 55px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 3px solid #000;
  font-weight: bold;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.unit.form input.submit:hover,
.unit.form input.submit:focus {
  color: #000;
  background-color: #FFCD00;
}
.unit.form input.submit:active {
  background-color: #FFB71B;
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: var(--textColor);
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
  font-size: 14px;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  background-color: #000;
  line-height: 1.375;
  cursor: pointer;
  color: #fff;
  padding: 28px 55px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 3px solid #000;
  font-weight: bold;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  color: #000;
  background-color: #FFCD00;
}
.two-step-verification-container a:active {
  background-color: #FFB71B;
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: bold;
}
.table td {
  padding: 5px 10px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: bold;
}
@media (max-width: 767px) {
  .part--table .table {
    font-size: 2.08604954vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
#home {
  float: left;
  height: 50px;
  margin: 0 auto;
}
.logo {
  width: auto;
  height: 100%;
}
#head {
  float: left;
  width: 100%;
}
.cb-layout2 #head,
.cb-layout3 #head,
.cb-layout4 #head,
.cb-layout5 #head,
.cb-layout6 #head {
  margin-top: calc(var(--spaceTotal) * 1 + 10px);
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  line-height: 1.375;
  color: var(--textColor);
  overflow: hidden;
  hyphens: none;
  display: flex;
  flex-direction: column;
  padding-top: 130px;
  box-sizing: border-box;
}
.cb-layout4 .wrapper {
  background-color: #FFB71B;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 88%;
  width: 1364px;
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section--five .desk,
.section--header .desk,
.section--footer .desk {
  width: 1630px;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--header {
  background-color: #000;
  padding: 37px 0 44px;
  box-sizing: border-box;
  z-index: 2001;
  position: fixed;
  top: 0;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
.cb-scroll-triggered--active .section--header {
  padding: 20px 0 20px;
}
.container {
  float: left;
  width: 100%;
  position: relative;
}
.container--head {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.headcontact {
  position: absolute;
  right: 0;
}
.headcontact a {
  color: #FFF;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.headcontact a:hover,
.headcontact a:focus {
  color: #FFCD00;
}
@media (max-width: 1023px) {
  .headcontact {
    display: none;
  }
}
.mobile-navi-animation .meta {
  float: left;
  width: 100%;
  font-size: 40px;
  line-height: 1.375;
  font-weight: bold;
  margin-bottom: 5px;
  color: #000;
}
.mobile-navi-animation .meta:hover,
.mobile-navi-animation .meta:focus {
  color: rgba(0, 0, 0, 0.8);
}
main {
  float: left;
  width: 100%;
  position: relative;
  flex: 1 0 auto;
}
.section--black  {
  --textColor: #FFF;
  background-color: #000;
}
.section--orange  {
  background-color: #FFB71B;
}
.container--mood {
  min-height: calc(60vh);
  /* Use vh as a fallback for browsers that do not support Custom Properties */
  padding: 120px 0;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  isolation: isolate;
}
.container--mood .content {
  float: left;
  width: 100%;
  position: relative;
  max-width: 88%;
  width: 1630px;
}
.container--mood .mood {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.container--moodtwo {
  margin-bottom: calc(var(--spaceTotal) * 2);
}
.container--moodtwo .content {
  float: left;
  width: 100%;
  position: relative;
}
.container--moodtwo .mood {
  float: left;
  width: 100%;
  height: 60vh;
  /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 60);
  padding: 12vw 0;
  box-sizing: border-box;
  position: relative;
  isolation: isolate;
}
.container--moodtwo .mood:after {
  content: '';
  position: absolute;
  top: 0;
  left: 12vw;
  height: 100%;
  width: 100vw;
  background-color: #FFB71B;
  z-index: -1;
}
.downlink {
  position: absolute;
  bottom: 50px;
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 58px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-down-white.svg);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.downlink:hover,
.downlink:focus {
  background-color: #FFB71B;
}
@media (max-width: 1023px) {
  .downlink {
    width: 40px;
    height: 38px;
    bottom: 20px;
  }
}
.downlinktwo {
  position: relative;
  float: left;
  cursor: pointer;
  width: 60px;
  height: 58px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-down-black.svg);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  margin-top: var(--spaceTotal);
}
.downlinktwo:hover,
.downlinktwo:focus {
  background-color: #FFB71B;
}
@media (max-width: 1023px) {
  .downlinktwo {
    width: 40px;
    height: 38px;
    bottom: 20px;
  }
}
.section--main .downlink {
  bottom: 40px;
  background-image: url(/images/arrow-down-black.svg);
}
.backlink {
  background-color: #000;
  line-height: 1.375;
  cursor: pointer;
  color: #fff;
  padding: 28px 55px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  margin-left: 50%;
  transform: translateX(-50%);
  border: 3px solid #000;
  color: #000;
  background-color: #FFF;
  margin-bottom: calc(var(--spaceTotal) * 4);
}
.backlink:hover,
.backlink:focus {
  color: #000;
  background-color: #FFCD00;
}
.backlink:active {
  background-color: #FFB71B;
}
.service_backtoangebot {
  background-color: #000;
  line-height: 1.375;
  cursor: pointer;
  color: #fff;
  padding: 28px 55px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  border: 3px solid #000;
  color: #000;
  background-color: #FFF;
  margin-top: calc(var(--spaceTotal) * 2);
  margin-bottom: calc(var(--spaceTotal) * 1);
}
.service_backtoangebot:hover,
.service_backtoangebot:focus {
  color: #000;
  background-color: #FFCD00;
}
.service_backtoangebot:active {
  background-color: #FFB71B;
}
.toplink {
  position: fixed;
  z-index: 1500;
  right: -60px;
  bottom: 40px;
  width: 60px;
  height: 57px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: transparent;
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: #FFF;
  background-image: url(/images/arrow-top-black.svg);
  transition: right 0.4s ease-out background-color 0.4s ease-out;
}
@media (max-width: 1023px) {
  .toplink {
    bottom: 20px;
    width: 40px;
    height: 37px;
  }
}
.toplink:hover,
.toplink:focus {
  background-color: #FFB71B;
}
.toplink.cb-goto-top-visible {
  right: 50px;
}
@media (max-width: 1023px) {
  .toplink.cb-goto-top-visible {
    right: 15px;
  }
}
.toplink.cb-scroll-triggered--active {
  position: absolute;
  bottom: 100%;
  margin-bottom: 40px;
}
@media (max-width: 1023px) {
  .toplink.cb-scroll-triggered--active {
    margin-bottom: 20px;
  }
}
.section--footer {
  flex-shrink: 0;
}
.footarea {
  float: left;
  width: 100%;
  position: relative;
  padding: 50px 0;
  box-sizing: border-box;
}
.footarea:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 5px;
  background-color: #FFB71B;
}
.footpart {
  float: left;
  width: 100%;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.28571429;
  color: var(--textColor);
}
@media (max-width: 1023px) {
  .footpart {
    padding-left: 90px;
    box-sizing: border-box;
  }
}
.footlogo {
  float: left;
  width: 58px;
  position: absolute;
  left: 0;
  top: -10px;
}
.section--black .footlogo {
  content: url('/images/logo-concepta-icon-white.svg');
}
.foottitle {
  color: #FFB71B;
}
.vcard {
  float: left;
  width: 100%;
}
.vcard address {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  color: var(--textColor);
}
.vcard .contact {
  float: left;
  width: 100%;
}
.vcard .contact a {
  color: var(--textColor);
}
.vcard .contact a:hover,
.vcard .contact a:focus {
  color: #FFCD00;
}
.opentimes {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.opentime {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.opentime--fr {
  margin-bottom: 0;
}
#legal {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#legal .meta {
  color: var(--textColor);
}
#legal .meta:hover,
#legal .meta:focus {
  color: #FFCD00;
}
a {
  color: #FFCD00;
  text-decoration: none;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-layout4 a {
  color: #666666;
}
a:hover,
a:focus {
  color: #b38f00;
}
.cb-layout4 a:hover,
.cb-layout4 a:focus {
  color: #000;
}
.part.link .open {
  background-color: #000;
  line-height: 1.375;
  cursor: pointer;
  color: #fff;
  padding: 28px 55px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 3px solid #000;
  font-weight: bold;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.part.link .open:hover,
.part.link .open:focus {
  color: #000;
  background-color: #FFCD00;
}
.part.link .open:active {
  background-color: #FFB71B;
}
h1 {
  color: var(--textColor);
  font-size: 18px;
  line-height: 1.38888889;
  font-weight: bold;
}
.cb-layout1 h1 {
  font-size: 16px;
  line-height: 1.125;
  font-weight: 500;
}
.cb-layout2 h1 {
  font-size: 40px;
  line-height: 1.25;
}
h2 {
  color: var(--textColor);
  font-size: 18px;
  line-height: 1.38888889;
  font-weight: bold;
}
h3 {
  color: #FFB71B;
  font-size: 46px;
  line-height: 1.36956522;
  font-weight: bold;
}
h4 {
  color: #FFB71B;
  font-size: 40px;
  line-height: 1.25;
  font-weight: bold;
}
h5 {
  color: var(--textColor);
  font-size: 23px;
  line-height: 1.34782609;
  font-weight: normal;
}
.preTitle {
  color: var(--textColor);
  font-size: 35px;
  line-height: 1.28571429;
  font-weight: bold;
}
p.norm,
p.loud {
  color: var(--textColor);
}
.section--one .area {
  margin-top: calc(var(--spaceTotal) * 1);
  margin-bottom: calc(var(--spaceTotal) * 1);
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  margin-left: -10px;
}
.cb-layout1 .section--one .area.main {
  margin-bottom: calc(var(--spaceTotal) * 4);
}
#view.cb-layout4 .section--one .area.main,
#view.cb-layout5 .section--one .area.main,
#view.cb-layout6 .section--one .area.main {
  margin-top: calc(var(--spaceTotal) * -1);
}
.section--five .area {
  margin-top: calc(var(--spaceTotal) * 1);
  margin-bottom: calc(var(--spaceTotal) * 4);
  display: flex;
  flex-wrap: wrap;
}
.cb-layout3 .section--four .farwest {
  min-height: 500px;
}
@media (max-width: 1023px) {
  .section--one .area .pure.wide {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (max-width: 1023px) {
  .section--one .area .pure.slim {
    width: calc(50% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
}
.section--one .area .pure.slim .part {
  margin: 0;
  text-align: center;
}
.section--five .area .unit {
  margin-top: 0;
  margin-bottom: 0;
}
.section--five .area .unit .part.pict a:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-size: 80% 80%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/designelement.svg);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.section--five .area .unit .part.pict a:hover:after,
.section--five .area .unit .part.pict a:focus:after {
  opacity: 1;
}
.togglenavigation {
  position: absolute;
  left: 0;
  width: 40px;
  height: 30px;
  cursor: pointer;
}
@media (max-width: 1023px) {
  .togglenavigation {
    width: 32px;
    height: 24px;
  }
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 5px;
  background-color: #FFF;
  border-radius: 0;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
@media (max-width: 767px) {
  .tline {
    height: 4px;
  }
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateY(-10px);
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateY(10px);
}
.mobile-navigation {
  position: fixed;
  top: 188px;
  left: max(6vw, calc((100vw - 1630px) / 2));
  z-index: 2000;
  width: 320px;
  height: auto;
  background-color: #FFCD00;
  overflow-y: scroll;
  padding: 25px;
  box-sizing: border-box;
  transform: translateY(-100%);
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateY(0);
}
.cb-scroll-triggered--active .mobile-navigation {
  top: 120px;
}
@media (max-width: 1023px) {
  .mobile-navigation {
    padding: 6vw;
    left: 0;
    right: 0;
    width: 100%;
    top: 130px;
  }
  .cb-scroll-triggered--active .mobile-navigation {
    top: 90px;
  }
}
.mobile-navi-animation {
  float: left;
  width: 100%;
  opacity: 0;
  transform: translateY(-100vh);
  transition: all 0s 0.6s cubic-bezier(0.65, 0, 0.35, 1);
}
body.cb-toggle-target-active .mobile-navi-animation {
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: translateY(0);
  opacity: 1;
}
.mobile-navigation div.navi {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 5px;
}
.mobile-navigation div.navi > .item.exit {
  margin-bottom: 0;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
  font-size: 40px;
  line-height: 1.375;
  font-weight: bold;
  color: #000;
}
.mobile-navigation div.navi > .item > .menu:hover,
.mobile-navigation div.navi > .item > .menu:focus {
  color: rgba(0, 0, 0, 0.8);
}
.mobile-navigation div.sub1 > .item > .menu {
  padding-right: 40px;
}
.mobile-navigation div.sub2 {
  overflow: hidden;
  max-height: 0;
}
.mobile-navigation div.sub2 > .item {
  opacity: 0;
  transform: scale(0.8);
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi {
  transition: none;
  max-height: 1000px;
  transition: max-height 2s;
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi > .item {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s;
}
.mobile-navigation .cb-toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
  width: 40px;
  height: 24px;
  background-size: 14px 14px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-down-black.svg);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navigation .cb-toggle.cb-toggle-active {
  transform: rotate(-180deg);
}
.unitFader {
  position: relative;
  float: left;
  width: 100%;
  margin-top: calc(var(--spaceTotal) * 1);
  margin-bottom: calc(var(--spaceTotal) * 4);
}
#view.side--empty .unitFader {
  display: none;
}
.unitFader__actions {
  float: left;
}
@media (max-width: 1023px) {
  .unitFader__actions {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.unitFader__link {
  float: left;
  width: 60px;
  height: 60px;
  border-style: solid;
  border-color: transparent;
  cursor: pointer;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: absolute;
}
@media (max-width: 1023px) {
  .unitFader__link {
    position: relative;
    width: 40px;
    height: 40px;
  }
}
.unitFader__link:hover {
  opacity: 0.8;
}
.unitFader__link--prev {
  background-image: url(/images/arrow-left-yellow.svg);
  left: -80px;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1023px) {
  .unitFader__link--prev {
    left: 0;
    top: 0;
    transform: translateY(0);
  }
}
.unitFader__link--next {
  background-image: url(/images/arrow-right-yellow.svg);
  right: -80px;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1023px) {
  .unitFader__link--next {
    right: 0;
    top: 0;
    transform: translateY(0);
  }
}
.unitFader__index {
  float: left;
  display: flex;
  flex-flow: row nowrap;
  margin-top: 15px;
}
.unitFader__indexItem {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 20px;
  background-color: #FFCD00;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.unitFader__indexItem.is-active {
  background-color: #FFB71B;
  transform: scale(1.2);
}
#view.north--empty .unitFader {
  display: none;
}
#view .unitFader .area {
  display: flex;
  align-items: center;
  overflow: hidden;
}
#view .unitFader .area .unit {
  flex-shrink: 0;
  margin-right: -100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.8s;
  transform: scale(0.8);
  pointer-events: none;
}
#view .unitFader .area .unit.is-visible {
  z-index: 2;
  pointer-events: initial;
  opacity: 1;
  transform: scale(1);
}
#view .ns-unitFilter__unit {
  display: none;
}
#view .ns-unitFilter__unit.ns-unitFilter__unit--activated {
  display: block;
  opacity: 0;
  transform: translateY(-20px) scale(0.98);
  transition: transform 0.4s, opacity 0.4s !important;
}
#view .ns-unitFilter__unit.ns-unitFilter__unit--active {
  opacity: 1;
  transform: translateY(0) scale(1);
}
.ns-unitFilter {
  float: left;
  width: 100%;
  position: relative;
  z-index: 20;
  margin-top: calc(var(--spaceTotal) * 2);
  margin-bottom: calc(var(--spaceTotal) * 2);
}
.ns-unitFilter__wrapper {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-right: 21.67630058%;
  box-sizing: border-box;
}
.ns-unitFilter__toggle {
  display: none;
}
.ns-unitFilter__label {
  float: left;
  margin-right: 25px;
  margin-bottom: 10px;
  font-size: 19px;
  line-height: 1.52631579;
}
@media (max-width: 1023px) {
  .ns-unitFilter__label {
    display: none;
  }
}
.ns-unitFilter__content {
  float: left;
  width: 100%;
}
.ns-unitFilter__list {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
@media (max-width: 767px) {
  .ns-unitFilter__list {
    flex-direction: column;
  }
}
.ns-unitFilter__actions {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.ns-unitFilter__link {
  display: block;
  text-align: center;
  border: dashed #FFCD00 2px;
  padding: 8px 30px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .ns-unitFilter__link--confirm {
    display: none;
  }
}
.ns-unitFilter__group {
  float: left;
  width: 100%;
  border-top: 1px solid var(--textColor);
  border-bottom: 1px solid var(--textColor);
  margin-top: -1px;
}
.ns-unitFilter__group:first-child {
  margin-top: 0;
}
.ns-unitFilter__groupToggle {
  float: left;
  width: 100%;
  font-size: 18px;
  line-height: 1.66666667;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  cursor: pointer;
  padding: 25px 20px 25px 0;
  box-sizing: border-box;
  position: relative;
  color: var(--textColor);
}
.ns-unitFilter__groupToggle:hover,
.ns-unitFilter__groupToggle:focus {
  color: var(--textColor);
}
.ns-unitFilter__groupToggle:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-style: solid;
  border-color: transparent;
  border-top-color: #000;
  border-width: 7px 5px 0 5px;
}
.ns-unitFilter__groupToggle.is-active:after {
  transform: translateY(-50%) rotate(-180deg);
}
.ns-unitFilter__groupContent {
  float: left;
  width: 100%;
  overflow: hidden;
  max-height: 0;
}
.ns-unitFilter__group.is-open .ns-unitFilter__groupContent {
  max-height: initial;
}
.ns-unitFilter__item {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  color: #FFB71B;
  width: calc(25% - 20px);
  font-size: 18px;
  line-height: 1.22222222;
  font-weight: bold;
}
.ns-unitFilter__item.is-active {
  color: #000;
}
@media (max-width: 1023px) {
  .ns-unitFilter__item {
    width: 100%;
    margin-left: 0;
    font-size: 16px;
    line-height: 1.25;
  }
}
/*# sourceMappingURL=./screen-small.css.map */